import { domReady } from '@roots/sage/client';
import MicroModal from 'micromodal';
import 'slick-carousel';

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    console.error(err);
  }

  MicroModal.init({
    onShow: modal => console.info(`${modal.id} is shown`),
    onClose: modal => console.info(`${modal.id} is hidden`),
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: false
  });

  $(".type-select2").select2({
    placeholder: "Property Type",
    closeOnSelect : true,
    allowHtml: true,
    allowClear: false,
    tags: true,
    dropdownAutoWidth: true,
    width: '250px',
  });

  $(".rooms-select2").select2({
    placeholder: "Bedrooms",
    closeOnSelect : true,
    allowHtml: true,
    allowClear: false,
    tags: true,
    dropdownAutoWidth: true,
    width: '200px',
  });

  // sticky nav
  var navbar = document.getElementById("stickynav");

  if (navbar) {
    var sticky = navbar.offsetTop;

    function stickNav() {
      if (window.pageYOffset + 140 >= sticky) {
        navbar.classList.add("stuck")
      } else {
        navbar.classList.remove("stuck");
      }
    }

    window.onscroll = function () { stickNav() };
  }

  // anchor actions
  const anchors = document.querySelectorAll('.anchor-item');
  let anchorList = anchors[0];

  anchors.forEach((anchor) => {
    anchor.addEventListener('click', () => {
      anchorList.classList.remove('active');
      anchorList = anchor;
      anchorList.classList.add('active');
    });
  });

  let hash = location.hash.replace(/^#/, '');

  // scroll to 
  function scrollto(hash) {
    // make sure we have a hash and element to scroll to
    if ((hash !== "") && ($(hash).length > 0)) {
      $('html, body').animate({
        scrollTop: $(hash).offset().top - 80,
      }, 800, function () {
        if (history.pushState) {
          history.pushState(null, null, hash);
        } else {
          window.location.hash = hash;
        }
      });
    }
  }

  // Scroll to Position on hash link
  $(".anchor-item a").on('click', function (event) {
    if ((this.hash !== "") && ($(this.hash).length > 0)) {
      event.preventDefault();
      scrollto(this.hash);
    }
  });

  // scroll down on page load
  if (hash) {
    setTimeout(function () {
      scrollto('#' + hash);
    }, 550);
  }

  // Set logo state
  let didScroll;

  $(window).scroll(function () {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      didScroll = false;
    }
  }, 250);

  $(".mobile-toggle").on('click', function () {
    $('.nav-primary').toggleClass('hidden');
  });

  $(".gallery-toggle").on('click', function () {
    $('.gallery-toggle').addClass('bg-white text-brand-dark');
    $('.gallery-toggle').removeClass('bg-brand-dark text-white');

    $(this).addClass('bg-brand-dark text-white');
    $(this).removeClass('bg-white text-brand-dark');

    $('[class^=image-]').removeClass('hidden');
    $('[class^=image-]').not('.image-'+$(this).data('target')).addClass('hidden');
  });

  $(".image-toggle").on('click', function () {
    $(this).parent().find('.image-toggle').addClass('bg-white text-brand-dark');
    $(this).parent().find('.image-toggle').removeClass('bg-brand-dark text-white');

    $(this).addClass('bg-brand-dark text-white');
    $(this).removeClass('bg-white text-brand-dark');

    $(this).parent().find('.image-image, .image-3d').addClass('hidden');
    $(this).parent().find('.image-'+$(this).data('target')).removeClass('hidden');
  });




  $(".btn-link").on('click', function () {
    // clear 
    $('.btn-link').removeClass('bg-brand-btn');
    $('.btn-link').addClass('bg-brand-light');

    // enable
    $(this).removeClass('bg-brand-light');
    $(this).addClass('bg-brand-btn');
  });

  $(".gallery-img").on('click', function () {
    var slideno = $(this).data('target');
   $('.slider').slick('slickGoTo', slideno - 1);
   $('.slider').slick('setPosition');
  });

  // slick slider init
  let $sliders = $('.slider');
  if ($sliders.length > 0) {
    for (let s = 0; s < $sliders.length; s++) {
      initSlider($($sliders[s]));
    }
  }

  function initSlider($slider) {
    $slider.slick({
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="39.681" height="81.879" viewBox="0 0 39.681 81.879"><path id="Path_3972" data-name="Path 3972" d="M1.107,80.866l-2.213-2.025L34.508,39.926-1.107,1.013,1.107-1.013,38.574,39.926Z" transform="translate(38.574 80.866) rotate(180)" fill="#f8f8f8"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="39.681" height="81.88" viewBox="0 0 39.681 81.88"><path id="Path_3967" data-name="Path 3967" d="M-11622.812-6587.828l-2.213-2.025,35.614-38.916-35.614-38.913,2.213-2.025,37.468,40.938Z" transform="translate(11625.024 6669.708)" fill="#f8f8f8"/></svg></button>',
    });
  }

   $(".inquire").on('click', function () {
    $('#menu-register a').trigger('click');
  });

   $("#type input").on('change', function () {

    var values = new Array();
    var count = 0
    
    $.each($("#type input[name='type']"), function() {
      if(this.checked){
        values.push($(this).val());
        count++;
      }
    });

    if(count === 0) {
      $("#type .count").text('');
    }else{
      $("#type .count").text('('+count+')');
    }

    // loop over listings
    if (values.length !== 0) {
      $('#listings .item').addClass('hidden');
    } else {
      // need to account for rooms next
      $('#listings .item').removeClass('hidden');
    }
    
    // hide / show results
    $.each(values, function (index, value) {
      $(".item[data-type='"+ value +"']").removeClass('hidden');
    });

    // disable options
    $("#rooms input[type='checkbox']").prop("disabled", true);
    $.each($(".item:not(.hidden)"), function (index, value) {
      $("#rooms input[value=" + $(this).data('rooms') + "]").prop("disabled", false)
      // console.log($(this).data('type'),$(this).data('rooms'));
    });
  });

  //  sort dropdown
   $("#sort").on('change', function () {
    var wrapper = $('#listings');
    switch (this.value) {
      case 'price-asc':
        wrapper.find('.item').sort(function(a, b) {
          // console.log(+a.dataset.price - +b.dataset.price);
          return +a.dataset.price - +b.dataset.price;
        }).appendTo(wrapper);
        break;
      case 'price-desc':
        wrapper.find('.item').sort(function(a, b) {
          return +b.dataset.price - +a.dataset.price;
        }).appendTo(wrapper);
        break;
      default:
        wrapper.find('.item').sort(function(a, b) {
          return +a.dataset.order - +b.dataset.order;
        }).appendTo(wrapper);
        break;
    }
  });

  $(".checkbox-dropdown").on('click', function () {
    $(this).toggleClass("is-active");
  });

  $(".checkbox-dropdown ul").on('click', function (e) {
      e.stopPropagation();
  });

  function initFilter() {
    $.each($('#listings .item'), function (index, value) {
      $("#type input[value=" + $(this).data('type') + "]").prop("disabled", false)
      $("#rooms input[value=" + $(this).data('rooms') + "]").prop("disabled", false)
    });
  }
  
  initFilter();
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
